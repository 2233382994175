import classes from "./Icons.module.css";

export const Icons = (handlers) => (
  <div className={classes.Icons}>
    <div
      onClick={() => {
        handlers.setBioHandler(true);
        handlers.setImageHandler(false);
        handlers.setProjectsBinHandler(false);
        handlers.setCloseBio(false);
      }}
      className={classes.notepadIcon}
    >
      <img src="https://i.ibb.co/HNbCz7w/notepad-document.png" alt="notepad" />
      <p>Notepad</p>
    </div>
    <div
      onClick={() => {
        handlers.setBioHandler(false);
        handlers.setImageHandler(true);
        handlers.setProjectsBinHandler(false);
        handlers.setCloseImage(false);
      }}
      className={classes.imageIcon}
    >
      <img
        src="https://i.ibb.co/mHGb879/sheet-with-painting.png"
        alt="myPhoto"
      />
      <p>Image</p>
    </div>
    <div
      onClick={() => {
        handlers.setBioHandler(false);
        handlers.setImageHandler(false);
        handlers.setProjectsBinHandler(true);
        handlers.setCloseProjectsBin(false);
      }}
      className={classes.projectsBinIcon}
    >
      <img
        src="https://i.ibb.co/0jc9DLv/recycle-bin-with-pap.png"
        alt="recycleBin"
      />
      <p>Projects Bin</p>
    </div>
  </div>
);
