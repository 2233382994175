import { useState, useEffect } from "react";
import { Bio } from "./components/Bio/Bio";
import Header from "./components/Header/Header";
import { Icons } from "./components/Icons/Icons";
import { LoadingScreen } from "./components/LoadingScreen/LoadingScreen";
import { Picture } from "./components/Picture/Picture";
import { ProjectsBin } from "./components/ProjectsBin/ProjectsBin";
import { Voxel } from "./components/Voxel/Voxel";

const App = () => {
  const getAge = birthDate => Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10);
  const BIO = `Hello, my name is Artyom and I am a ${getAge('1999-11-11')} y.o. frontend developer from Saint Petersburg, Russia.\r\n\r\nAt the moment I am working at Dr.Web, and I am also a master's student at ITMO University and studying at the Faculty of Software Engineering and Computer Technology.\r\n\r\nPress 'Start' button bellow to get my socials\r\nOr you can look at my projects in 'Bin' folder :)\r\nGood luck and have fun!`;

  const [bio, setBio] = useState(BIO);

  const [bioHandler, setBioHandler] = useState(true);
  const [imageHandler, setImageHandler] = useState(false);
  const [projectsBinHandler, setProjectsBinHandler] = useState(false);

  const [closeBio, setCloseBio] = useState(false);
  const [closeImage, setCloseImage] = useState(false);
  const [closeProjectsBin, setCloseProjectsBin] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [projectsCounter, setProjectsCounter] = useState(2);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      {isLoading ? <LoadingScreen /> : null}
      <Voxel />
      <Icons
        closeProjectsBin={closeProjectsBin}
        setCloseProjectsBin={setCloseProjectsBin}
        closeImage={closeImage}
        setCloseImage={setCloseImage}
        closeBio={closeBio}
        setCloseBio={setCloseBio}
        bioHandler={bioHandler}
        setBioHandler={setBioHandler}
        imageHandler={imageHandler}
        setImageHandler={setImageHandler}
        projectsBinHandler={projectsBinHandler}
        setProjectsBinHandler={setProjectsBinHandler}
      />
      {bioHandler ? (
        <Bio
          bio={bio}
          setBio={setBio}
          closeBio={closeBio}
          setCloseBio={setCloseBio}
          bioHandler={bioHandler}
          setBioHandler={setBioHandler}
        />
      ) : null}
      {imageHandler ? (
        <Picture
          closeImage={closeImage}
          setCloseImage={setCloseImage}
          imageHandler={imageHandler}
          setImageHandler={setImageHandler}
        />
      ) : null}
      {projectsBinHandler ? (
        <ProjectsBin
          projectsCounter={projectsCounter}
          setProjectsCounter={setProjectsCounter}
          closeProjectsBin={closeProjectsBin}
          setCloseProjectsBin={setCloseProjectsBin}
          projectsBinHandler={projectsBinHandler}
          setProjectsBinHandler={setProjectsBinHandler}
        />
      ) : null}
      <div className='space'></div>
      <Header
        closeProjectsBin={closeProjectsBin}
        setCloseProjectsBin={setCloseProjectsBin}
        closeImage={closeImage}
        setCloseImage={setCloseImage}
        closeBio={closeBio}
        setCloseBio={setCloseBio}
        bioHandler={bioHandler}
        setBioHandler={setBioHandler}
        imageHandler={imageHandler}
        setImageHandler={setImageHandler}
        projectsBinHandler={projectsBinHandler}
        setProjectsBinHandler={setProjectsBinHandler}
      />
    </>
  );
};

export default App;
