import { useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { Suspense } from "react";
import { OrbitControls } from "@react-three/drei";
import Win95 from "../../models/Win95";
import classes from "./Voxel.module.css";

const RotationLogic = () => {
  const ref = useRef(null);
  const speed = 2

  useFrame(() => {
    const angle = ref.current.getAzimuthalAngle();
    if (angle <= -0.9)
      ref.current.autoRotateSpeed = -speed
    if (angle >= 0.9)
      ref.current.autoRotateSpeed = speed
  });
  return (
    <OrbitControls
      ref={ref}
      autoRotate={true}
      autoRotateSpeed={speed}
      enableZoom={false}
      enablePan={false}
      maxAzimuthAngle={1}
      minAzimuthAngle={-1}
    />
  );
};

export const Voxel = () => {
  return (
    <div className={classes.Voxel}>
      <Canvas camera={{ position: [10, 5, 35], zoom: 3.5 }}>
        <RotationLogic />
        <hemisphereLight intensity={0.55} />
        <spotLight
          position={[10, 10, 10]}
          angle={0.7}
          penumbra={1}
          intensity={2}
          castShadow
        />
        <Suspense fallback={null}>
          <Win95 />
        </Suspense>
      </Canvas>
    </div>
  );
};
